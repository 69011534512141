<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    class="alert"
  >
    <path
      d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      class="a"
    />
  </svg>
</template>

<script>
export default {
  name: 'Alert',
};
</script>

<style >
.alert > .a {
  fill: currentColor;
  opacity: 1;
}
</style>
